<template>
<div>
    <b-row>

        <!-- <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LA HIGIENE E INOCUIDAD DE ALIMENTOS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaTemperaturas.length}}</span>
                            <br>
                            <span class="text-muted">Higiene e inocuidad de alimentos registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col> -->
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de ficha de sintomatología</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit()">
                            <b-row>
                                <b-col md="6">
                                    <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                        <b-form-group label="Proyecto:" class="mb-2">
                                            <v-select @input="obtenerPaxProyecto" :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGenerales.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGenerales.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <validation-provider name="pax" rules="required" v-slot="{errors}">
                                        <b-form-group label="Pax:" class="mb-2">
                                            <v-select :reduce="listaPax =>listaPax.idPax" label="pax" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGenerales.idPax , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGenerales.idPax" :options="listaPax" @search:blur="blurPax">
                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Itinerarios'}"> Registra aquí</router-link></span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>

                    <b-row class="justify-content-center text-center">
                        <b-col cols="3" class="my-2">
                            <b-button block variant="custom" @click="verrificarCampos(1)">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Registrar temperatura</span>
                            </b-button>
                        </b-col>
                        <b-col cols="3" class="my-2">
                            <b-button block variant="custom" @click="verrificarCampos(2)">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Ficha sintomatológica</span>
                            </b-button>
                        </b-col>
                        <b-col cols="3" class="my-2">
                            <b-button block variant="custom" @click="verrificarCampos(3)">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Sospechoso / confirmado de Covid</span>
                            </b-button>
                        </b-col>
                        <!-- <b-col cols="3" class="my-2">
                            <b-button block variant="custom" @click="verrificarCampos(4)">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Lista de contactos directos</span>
                            </b-button>
                        </b-col>
                        <b-col cols="3" class="my-2">
                            <b-button block variant="custom" @click="verrificarCampos(5)">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Generar acta de persona en riesgo Covid</span>
                            </b-button>
                        </b-col>
                        <b-col cols="3" class="my-2">
                            <b-button block variant="custom" @click="verrificarCampos(6)">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Seguimiento teletriaje</span>
                            </b-button>
                        </b-col>
                        <b-col cols="3" class="my-2">
                            <b-button block variant="custom" @click="verrificarCampos(7)">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Agregar indicadores o detalle de paciente</span>
                            </b-button>
                        </b-col> -->
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Ficha de sintomatología registradas </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12">
                            <b-tabs content-class="mt-3" variant="pills">
                                <b-tab title="Registros de temperaturas" active>
                                    <b-row>
                                        <b-col md="3">
                                            <b-form-group label="Registros por página" class="text-muted">
                                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                        </b-col>
                                        <b-col md="3">
                                            <b-form-group label="Busqueda:" class="text-muted">
                                                <b-input-group size="xl">
                                                    <b-input-group-prepend is-text>
                                                        <i class="fas fa-search my-0 mx-0"></i>
                                                    </b-input-group-prepend>
                                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                            <b-table bordered hover show-empty mediun responsive outlined :items="listaTemperaturas" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                <template v-slot:cell(opciones)="param">
                                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarCheckList(param)" variant="dark" class=" mr-1 mb-1">
                                                        <span class="btn-inner--icon">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </span>
                                                    </b-button>

                                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCheckList(param)" variant="danger">
                                                        <span class="btn-inner--icon">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </span>
                                                    </b-button>
                                                </template>
                                                <template v-slot:cell(idProyecto)="param">
                                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                                </template>
                                                <!-- <template v-slot:cell(cantidadChecksSi)="param">
                                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==2).length}}
                                                </template>
                                                <template v-slot:cell(cantidadChecksNo)="param">
                                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==1).length}}
                                                </template> -->

                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="Registros de fichas sintomatológicas">
                                    <b-row>
                                        <b-col md="3">
                                            <b-form-group label="Registros por página" class="text-muted">
                                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                        </b-col>
                                        <b-col md="3">
                                            <b-form-group label="Busqueda:" class="text-muted">
                                                <b-input-group size="xl">
                                                    <b-input-group-prepend is-text>
                                                        <i class="fas fa-search my-0 mx-0"></i>
                                                    </b-input-group-prepend>
                                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                            <b-table bordered hover show-empty mediun responsive outlined :items="listaTemperaturas" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                <template v-slot:cell(opciones)="param">
                                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarCheckList(param)" variant="dark" class=" mr-1 mb-1">
                                                        <span class="btn-inner--icon">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </span>
                                                    </b-button>

                                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCheckList(param)" variant="danger">
                                                        <span class="btn-inner--icon">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </span>
                                                    </b-button>
                                                </template>
                                                <template v-slot:cell(idProyecto)="param">
                                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                                </template>
                                                <!-- <template v-slot:cell(cantidadChecksSi)="param">
                                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==2).length}}
                                                </template>
                                                <template v-slot:cell(cantidadChecksNo)="param">
                                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==1).length}}
                                                </template> -->

                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="Registro de sospecho / confirmado de Covid">
                                    <b-row>
                                        <b-col md="3">
                                            <b-form-group label="Registros por página" class="text-muted">
                                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                        </b-col>
                                        <b-col md="3">
                                            <b-form-group label="Busqueda:" class="text-muted">
                                                <b-input-group size="xl">
                                                    <b-input-group-prepend is-text>
                                                        <i class="fas fa-search my-0 mx-0"></i>
                                                    </b-input-group-prepend>
                                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                            <b-table bordered hover show-empty mediun responsive outlined :items="listaTemperaturas" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                <template v-slot:cell(opciones)="param">
                                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarCheckList(param)" variant="dark" class=" mr-1 mb-1">
                                                        <span class="btn-inner--icon">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </span>
                                                    </b-button>

                                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCheckList(param)" variant="danger">
                                                        <span class="btn-inner--icon">
                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                        </span>
                                                    </b-button>
                                                </template>
                                                <template v-slot:cell(idProyecto)="param">
                                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                                </template>
                                                <!-- <template v-slot:cell(cantidadChecksSi)="param">
                                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==2).length}}
                                                </template>
                                                <template v-slot:cell(cantidadChecksNo)="param">
                                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==1).length}}
                                                </template> -->

                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarTemperatura" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo registro de temperatura</span>
            </h6>
            <CButtonClose @click="modalRegistrarTemperatura = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarTemperatura)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="Tipo de temperatura" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo de temperatura:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" v-model="datosTemperatura.tipoTemperatura" :options="[{value:1,text:'Temperatura de ingreso'},{value:2,text:'Temperatura de salidad'}]">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="temperatura del paciente" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Temperatura del paciente:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" v-model="datosTemperatura.temperatura" placeholder="Ingrese la temperatura"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosTemperatura.fecha"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="¿es sospechoso de COVID-19?" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="¿Es sospechoso de COVID-19?:" class="mb-2">
                                <b-form-radio-group plain :state="getValidationState(validationContext)" v-model="datosTemperatura.esSospechoso" :options="comboSiNo"> </b-form-radio-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarTemperatura = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalRegistrarFichaSintomatologica" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Registrar ficha sintomatológica</span>
            </h6>
            <CButtonClose @click="modalRegistrarFichaSintomatologica = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarFichaSintomatologica)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosSintomatologica.fecha"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <span class="h6 text-muted">En los últimos 14 días calendario ha tenido algunos de estos síntomas siguientes:</span>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Sensación de alza de temperatura o fiebre:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio1" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Tos, estornudos o dificultad para respirar:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio2" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Expectoración o flema amarrilla o verdosa:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio3" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Contacto con persona(s) con un caso confirmado de COVID-19:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio4" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Está tomando alguna medicación (detallar cuál o cuáles):" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio5" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" v-if="datosSintomatologica.radio5==2">
                        <b-form-group label="Medicamentos:" class="mb-2">
                            <b-form-input plain v-model="datosSintomatologica.medicamentos" placeholder="Ingrese los medicamentos"> </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Dolor de cabeza:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio6" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Dolor nuscular:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio7" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Dolor de garganta:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio8" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Náuseas / Diarrea:" class="mb-2">
                            <b-form-radio-group plain v-model="datosSintomatologica.radio9" :options="comboSiNo"> </b-form-radio-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarFichaSintomatologica = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalRegistrarSospechoso" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Registrar sospechoso de COVID-19</span>
            </h6>
            <CButtonClose @click="modalRegistrarSospechoso = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarSospechoso)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="resultado de prueba serológica" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Resultado de prueba serológica:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" v-model="datosSospechoso.resultadoSerologica" placeholder="Ingrese el resultado"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="resultado de prueba molecular" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Resultado de prueba molecular:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" v-model="datosSospechoso.resultadoMolecular" placeholder="Ingrese el resultado"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha inicio de aislamiento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha inicio de aislamiento:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosSospechoso.fechaInicio"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha fin de aislamiento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha fin de aislamiento:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosSospechoso.fechaFin"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tiempo de aislamiento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tiempo de aislamiento:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" v-model="datosSospechoso.tiempoAislamiento" placeholder="Ingrese el tiempo de aislamiento"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Lugar de detección" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Lugar de detección:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" v-model="datosSospechoso.lugarDeteccion" placeholder="Ingrese el lugar de detección"> </b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="¿es persona en riesgo (mayor a 65 años)?" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="¿Es persona en riesgo (mayor a 65 años)?:" class="mb-2">
                                <b-form-radio-group plain :state="getValidationState(validationContext)" v-model="datosSospechoso.esPersonaRiesgo" :options="comboSiNo"> </b-form-radio-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarSospechoso = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarCheckList" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar check list</span></h6>
            <CButtonClose @click="modalActualizarCheckList = false" class="text-white" />
        </template>
        <validation-observer ref="observerxcxc" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarCheckList)">
                <b-row>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarCheckList = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import firebase from 'firebase';
const dbCheckListHIA = firebase.firestore().collection('checkListHIA');
const dbProyecto = firebase.firestore().collection('proyectos');
const dbItinerario = firebase.firestore().collection('itinerarios');
const dbTemperatura = firebase.firestore().collection('temperaturas');
const dbFichaSintomatologica = firebase.firestore().collection('fichasSintomatologicas');
const dbSospechoso = firebase.firestore().collection('sospechososCovid');

export default {
    data() {
        return {
            disabled: false,
            listaTemperaturas: [],
            listaFichasSintomatologicas: [],
            listaSospechosos: [],
            camposLote: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "idProyecto",
                    label: "Proyecto",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksSi",
                    label: "Checks si cumple",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksNo",
                    label: "Checks no cumple",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarTemperatura: false,
            modalRegistrarFichaSintomatologica: false,
            modalRegistrarSospechoso: false,

            modalActualizarCheckList: false,
            comboSiNo: [{
                    value: 2,
                    text: 'SI'
                },
                {
                    value: 1,
                    text: 'No'
                },
            ],
            datosGenerales: {
                idCliente: '',
                idProyecto: '',
                idPax: '',
            },
            datosFicha: {
                idCliente: '',

                datosSintomatologica: {},
                datosSospechoso: {},
                datosContactos: {},
                datosActa: {},
                datosTeletriaje: {},
                datosIndicadores: {}
            },
            datosTemperatura: {
                idCliente: '',
                idProyecto: '',
                idPax: '',
                tipoTemperatura: null,
                temperatura: '',
                fecha: moment().format('YYYY-MM-DD'),
                esSospechoso: 1,
            },
            datosSintomatologica: {
                idCliente: '',
                idProyecto: '',
                idPax: '',
                fecha: moment().format('YYYY-MM-DD'),
                radio1: 1,
                radio2: 1,
                radio3: 1,
                radio4: 1,
                radio5: 1,
                medicamentos: '',
                radio6: 1,
                radio7: 1,
                radio8: 1,
                radio9: 1,
            },
            datosSospechoso: {
                idCliente: '',
                idProyecto: '',
                idPax: '',
                resultadoSerologica: '',
                resultadoMolecular: '',
                fechaInicio: moment().format('YYYY-MM-DD'),
                fechaFin: moment().format('YYYY-MM-DD'),
                tiempoAislamiento: '',
                lugarDeteccion: '',
                esPersonaRiesgo: 1,
            },
            datosTemperaturaActualizar: {
                idTemperatura: '',
                idCliente: '',
                idProyecto: '',
                idPax: '',
                tipoTemperatura: null,
                temperatura: '',
                fecha: '',
                esSospechoso: '',
            },
            datosFichaActualizar: {
                datosTemperaturaActualizar: {
                    idCheckList: '',
                    idCliente: '',
                    idProyecto: '',
                    idPax: '',
                },
            },
            comboProyecto: [],
            listaPax: [],
            arrayPax: [],
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedFormActualizar.refs.proyecto.validate();
        },
        blurPax() {
            this.computedForm.refs.pax.validate();
        },
        blurPaxActualizar() {
            this.computedFormActualizar.refs.pax.validate();
        },
        obtenerPaxProyecto(e) {
            this.listaPax = this.arrayPax.filter(x => x.idProyecto == e);
        },
        verrificarCampos(cond) {
            if (cond == 1 && (this.datosGenerales.idProyecto == '' || this.datosGenerales.idPax == '')) {
                this.swat('error', 'Elija el proyecto y/o el pax para continuar!')
            } else if (cond == 1 && (this.datosGenerales.idProyecto != '' || this.datosGenerales.idPax != '')) {
                this.modalRegistrarTemperatura = true;
            }
            if (cond == 2 && (this.datosGenerales.idProyecto == '' || this.datosGenerales.idPax == '')) {
                this.swat('error', 'Elija el proyecto y/o el pax para continuar!')
            } else if (cond == 2 && (this.datosGenerales.idProyecto != '' || this.datosGenerales.idPax != '')) {
                this.modalRegistrarFichaSintomatologica = true;
            }
            if (cond == 3 && (this.datosGenerales.idProyecto == '' || this.datosGenerales.idPax == '')) {
                this.swat('error', 'Elija el proyecto y/o el pax para continuar!')
            } else if (cond == 3 && (this.datosGenerales.idProyecto != '' || this.datosGenerales.idPax != '')) {
                this.modalRegistrarSospechoso = true;
            }

        },
        listarProyectos() {
            let me = this;
            dbProyecto
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosGenerales.idCliente))
                .get()
                .then((querySnapshot) => {
                    me.comboProyecto = [];
                    querySnapshot.forEach((doc) => {
                        me.comboProyecto.push({
                            idProyecto: doc.id,
                            nombre: doc.data().nombre,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.comboProyecto.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
                    me.listarTemperaturas();
                    me.listarItinerario();
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarItinerario() {
            let me = this;
            dbItinerario
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosGenerales.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.arrayPax = [];
                    let pax = [];
                    querySnapshot.forEach((doc) => {
                        pax.push({
                            idItinerario: doc.id,
                            idProyecto: doc.data().idProyecto,
                            listaPax: doc.data().listaPax,
                        });
                    });
                    pax.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))

                    for (const i in pax) {
                        for (const j in pax[i].listaPax) {
                            me.arrayPax.push({
                                idItinerario: pax[i].idItinerario,
                                idProyecto: pax[i].idProyecto,
                                pax: pax[i].listaPax[j].nombres,
                                idPax: pax[i].listaPax[j].idPax,
                            });
                        }
                    }
                });
        },
        listarTemperaturas() {
            let me = this;
            dbTemperatura
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosGenerales.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaTemperaturas = [];
                    querySnapshot.forEach((doc) => {
                        me.listaTemperaturas.push({
                            idTemperatura: doc.id,
                            idCliente: doc.data().idCliente,
                            idProyecto: doc.data().idProyecto,
                            idPax: doc.data().idPax,
                            tipoTemperatura: doc.data().tipoTemperatura,
                            temperatura: doc.data().temperatura,
                            fecha: doc.data().fecha,
                            esSospechoso: doc.data().esSospechoso,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaTemperaturas.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        listarFichasSintomatologicas() {
            let me = this;
            dbFichaSintomatologica
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosGenerales.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaFichasSintomatologicas = [];
                    querySnapshot.forEach((doc) => {
                        me.listaFichasSintomatologicas.push({
                            idTemperatura: doc.id,
                            idCliente: doc.data().idCliente,
                            idProyecto: doc.data().idProyecto,
                            idPax: doc.data().idPax,
                            fecha: doc.data().fecha,
                            radio1: doc.data().radio1,
                            radio2: doc.data().radio2,
                            radio3: doc.data().radio3,
                            radio4: doc.data().radio4,
                            radio5: doc.data().radio5,
                            medicamentos: doc.data().medicamentos,
                            radio6: doc.data().radio6,
                            radio7: doc.data().radio7,
                            radio8: doc.data().radio8,
                            radio9: doc.data().radio9,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaFichasSintomatologicas.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        listarSospechosos() {
            let me = this;
            dbSospechoso
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosGenerales.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaSospechosos = [];
                    querySnapshot.forEach((doc) => {
                        me.listaSospechosos.push({
                            idTemperatura: doc.id,
                            idCliente: doc.data().idCliente,
                            idProyecto: doc.data().idProyecto,
                            idPax: doc.data().idPax,
                            tipoTemperatura: doc.data().tipoTemperatura,
                            temperatura: doc.data().temperatura,
                            fecha: doc.data().fecha,
                            esSospechoso: doc.data().esSospechoso,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaSospechosos.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                    me.filasTotales = me.listaSospechosos.length;
                });
        },
        registrarTemperatura() {
            let me = this;
            me.disabled = true;
            dbTemperatura.add({
                    idCliente: me.datosGenerales.idCliente,
                    idProyecto: me.datosGenerales.idProyecto,
                    idPax: me.datosGenerales.idPax,
                    tipoTemperatura: me.datosTemperatura.tipoTemperatura,
                    temperatura: me.datosTemperatura.temperatura,
                    fecha: me.datosTemperatura.fecha,
                    esSospechoso: me.datosTemperatura.esSospechoso,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarTemperatura = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        registrarFichaSintomatologica() {
            let me = this;
            me.disabled = true;
            dbFichaSintomatologica.add({
                    idCliente: me.datosGenerales.idCliente,
                    idProyecto: me.datosGenerales.idProyecto,
                    idPax: me.datosGenerales.idPax,
                    fecha: me.datosSintomatologica.fecha,
                    radio1: me.datosSintomatologica.radio1,
                    radio2: me.datosSintomatologica.radio2,
                    radio3: me.datosSintomatologica.radio3,
                    radio4: me.datosSintomatologica.radio4,
                    radio5: me.datosSintomatologica.radio5,
                    medicamentos: me.datosSintomatologica.medicamentos,
                    radio6: me.datosSintomatologica.radio6,
                    radio7: me.datosSintomatologica.radio7,
                    radio8: me.datosSintomatologica.radio8,
                    radio9: me.datosSintomatologica.radio9,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarFichaSintomatologica = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        registrarSospechoso() {
            let me = this;
            me.disabled = true;
            dbSospechoso.add({
                    idCliente: me.datosGenerales.idCliente,
                    idProyecto: me.datosGenerales.idProyecto,
                    idPax: me.datosGenerales.idPax,
                    resultadoSerologica: me.datosSospechoso.resultadoSerologica,
                    resultadoMolecular: me.datosSospechoso.resultadoMolecular,
                    fechaInicio: me.datosSospechoso.fechaInicio,
                    fechaFin: me.datosSospechoso.fechaFin,
                    tiempoAislamiento: me.datosSospechoso.tiempoAislamiento,
                    lugarDeteccion: me.datosSospechoso.lugarDeteccion,
                    esPersonaRiesgo: me.datosSospechoso.esPersonaRiesgo,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarSospechoso = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarCheckList() {
            let me = this;
            me.disabled = true;
            dbCheckListHIA.doc(me.datosTemperaturaActualizar.idTemperatura).update({
                    idCliente: me.datosTemperaturaActualizar.idCliente,
                    idProyecto: me.datosTemperatura.idProyecto,
                    idPax: me.datosTemperatura.idPax,
                    tipoTemperatura: me.datosTemperatura.tipoTemperatura,
                    temperatura: me.datosTemperatura.temperatura,
                    fecha: me.datosTemperatura.fecha,
                    esSospechoso: me.datosTemperatura.esSospechoso,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarCheckList = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarCheckList(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el check list?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbCheckListHIA.doc(param.item.idCheckList).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirModalActualizarCheckList(param) {
            this.datosTemperaturaActualizar.idCheckList = param.item.idCheckList;
            this.datosTemperaturaActualizar.idProyecto = param.item.idProyecto;
            this.datosTemperaturaActualizar.datosEvaluacion = param.item.datosEvaluacion;
            this.modalActualizarCheckList = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarTemperatura() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosTemperatura.tipoTemperatura = null;
            this.datosTemperatura.temperatura = '';
            this.datosTemperatura.fecha = moment().format('YYYY-MM-DD');
            this.datosTemperatura.esSospechoso = 1;

        },
        resetModalActualizarCheckList() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarTemperatura: function (val) {
            if (val == false) {
                this.resetModalRegistrarTemperatura();
            }
        },
        modalActualizarCheckList: function (val) {
            if (val == false) {
                this.resetModalActualizarCheckList();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosGenerales.idCliente = user.idCliente;
            //this.listarTemperaturas();
            this.listarProyectos();

        }
    }

}
</script>
